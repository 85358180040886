import React from "react"
import { graphql } from "gatsby"
import { Story as IStory } from "../types/story"
// @ts-ignore
import { HelmetDatoCms } from "gatsby-source-datocms"
import { PostHeader } from "../layout/PostHeader"
import { DatoCmsContent } from "../layout/DatoCmsContent"
import { TeamMember } from "../types/TeamMember"
import { Gallery } from "../layout/DatoCmsContent/sections/Gallery"
import styled from "styled-components"
import device from "../components/Theme/devices"
import { Favicon } from "../components/Favicon"

const GalleryWrapper = styled.div`
  padding-top: 40px;
  padding-bottom: 80px;

  @media (${device.desktop}) {
    padding-top: 103px;
    padding-bottom: 210px;
  }
`

const Story: React.FC<PersonData> = ({ data: { person } }) => (
  <article>
    <PostHeader
      image={person.photo}
      imageStyle={"round"}
      showSocialMediaLinks={true}
      author={person}
      title={person.name}
      titleSecondLine={person.role}
      description={person.description || ""}
      subtitle={person.subtitle}
    />
    {person.content.map((content, i) => (
      <DatoCmsContent
        //@ts-ignore
        key={content?.id}
        verticalMargins={"article"}
        before={i > 0 ? person.content[i - 1] : undefined}
        after={person.content[i + 1] || undefined}
        {...content}
      />
    ))}
    {person.relatedTeamMembers && (
      <Gallery
        //@ts-ignore
        as={GalleryWrapper}
        id={"related"}
        __typename={"DatoCmsGallery"}
        title={person.relatedTeamMembersTitle}
        description={person.relatedTeamMembersDescription}
        items={person.relatedTeamMembers}
      />
    )}
    <HelmetDatoCms seo={person.seoMetaTags} />
    <Favicon />
  </article>
)

export default Story

type PersonData = {
  data: {
    person: TeamMember & { seoMetaTags: any }
  }
}

export const query = graphql`
  query($id: String) {
    person: datoCmsTeamMember(id: { eq: $id }) {
      ...TeamMemberData

      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
